<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">档案管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">一企一档列表</a>
         
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="企业名称" class="searchboxItem ci-full">
              <span class="itemLabel">企业名称:</span>
              <el-input
                v-model="enterpriseName"
                type="text"
                size="small"
                placeholder="请输入企业名称"
                clearable
              />
            </div>
            <el-button
              class="bgc-bv"
              style="margin:0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
                width="100px"
              />
              <el-table-column
                label="企业名称"
                align="left"
                prop="enterpriseName"
                show-overflow-tooltip
              />
              <el-table-column
                label="培训机构"
                align="left"
                prop="compName"
                show-overflow-tooltip
              />
              <el-table-column
                label="添加时间"
                align="left"
                prop="createTime"
                width="150"
                show-overflow-tooltip
              >
              <template slot-scope="scope">
                  {{scope.row.createTime | moment}}
              </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="150px"
              >
                <div slot-scope="scope" class="flexcc">
                 
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="
                      handlArchivesOfEachIssue(
                       scope.row.enterpriseId,
                       scope.row.enterpriseName,
                        
                      )
                    "
                    >每期档案</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="
                      handlEnterpriseArchives(
                       scope.row.enterpriseId,
                       scope.row.enterpriseName,
                       scope.row.compId
                      )
                    "
                    >企业档案</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "OneEnterpriseOneFileJg",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      enterpriseName: "", //题库名称
    };
  },
  created() {

  },
  computed: {},
  methods: {
    /* 获取列表数据 */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.enterpriseName) {
        params.enterpriseName = this.enterpriseName;
      }
      this.doFetch(
        {
          url: "/eighteenEnterprise/enterprise/page",
          params,
          pageNum,
        }
      );
    },
    /* 每期档案 */
    handlArchivesOfEachIssue(enterpriseId,enterpriseName) {
        this.$router.push({
            path:'/web/ArchivesOfEachIssueJg',
            query:{
                enterpriseId,
                enterpriseName
            }
      });
    },
    /* 企业档案 */
    handlEnterpriseArchives(enterpriseId,enterpriseName,compId) {
        this.$router.push({
            path:'/web/OneEnterpriseArchivesJg',
            query:{
                enterpriseId,
                enterpriseName,
                compId
            }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        } else {
           this.getData();
        }
        // if (oldVal.query.stu == "add") {
        //   (this.recommendName = ""),
        //     (this.areaId = ""),
        //     (this.terminals = ""),
        //     (this.pageNum = 1);
        //   this.getData(-1);
        // }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less"></style>
